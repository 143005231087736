<template>
    <ion-page>
        <TheHeader />

        <ion-content>
            <div class="page-planning-archive">
                <h1 class="page-title fs-20">
                    {{ countSalesPlanned }} {{ $t("salesPlanning.pagetitle")
                    }}<br />
                    <small class="fs-14 mt-2"
                        >{{ countSalesNotPlanned }}
                        {{ $t("salesPlanning.pagesubtitle") }}</small
                    >
                </h1>
                <ion-item color="primary" v-if="salesPlanningList.length === 0">
                    {{ $t("salesPlanning.noElements") }}
                </ion-item>
                <div
                    class=""
                    v-for="(salesPlanning, week) in groupOfSalesPlanning"
                    :key="week"
                >
                    <div class="week-title fs-14 text-right">
                        {{ $t("salesPlanning.week") }}
                        {{ formatWeek(week) }}
                    </div>
                    <ion-card
                        v-for="sale in salesPlanning"
                        :key="sale.transportId"
                        @click="openTransport(sale.transportId)"
                    >
                        <ion-card-header>
                            <div
                                class="
                                    d-flex
                                    align-items-center
                                    border-bottom
                                    pb-1
                                    text-dark
                                "
                            >
                                <div class="w-100">
                                    <strong>{{
                                        formatDate(sale.loadDate)
                                    }}</strong>
                                </div>
                                <div
                                    class="text-nowrap mr-3"
                                    v-if="sale.state === 1"
                                >
                                    <font-awesome-icon
                                        icon="clock"
                                    />&nbsp;<strong>{{
                                        formatHours(sale.loadDate)
                                    }}</strong>
                                </div>
                                <span
                                    class="badge"
                                    :class="
                                        sale.state === 1
                                            ? 'dark-grey'
                                            : 'light-grey'
                                    "
                                    >{{ sale.transportCode }}</span
                                >
                                <span class="fs-18 cursor-pointer" slot="end">
                                    <ion-icon
                                        :ios="chevronForwardOutline"
                                        :md="chevronForwardSharp"
                                    ></ion-icon>
                                </span>
                            </div>
                        </ion-card-header>
                        <ion-card-content>
                            <div class="d-flex text-dark">
                                <div>
                                    <img
                                        :src="sale.productIconUrl"
                                        :alt="sale.productName"
                                    />
                                </div>
                                <div class="w-100 pl-3">
                                    <h1>{{ sale.productName }}</h1>
                                    <span
                                        class="
                                            badge
                                            text-lowercase text-white
                                            default-orange
                                        "
                                        :style="
                                            'background-color:' +
                                            sale.manufactureColor +
                                            ';'
                                        "
                                        >{{ sale.productManufacture }}</span
                                    >&nbsp;
                                    <span class="badge badge-outline">{{
                                        sale.productVariety
                                    }}</span>
                                </div>
                                <div class="pl-3 pt-2 text-right">
                                    <span class="text-nowrap"
                                        >{{ defaultWeight }}Kg</span
                                    ><br />
                                    <strong class="text-nowrap"
                                        >{{ sale.price }}€/Kg</strong
                                    >
                                </div>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./SalesPlanning.ts"></script>

<style lang="scss" src="./SalesPlanning.scss"></style>
