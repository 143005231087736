import { Vue, Options } from 'vue-class-component';
import { chevronForwardOutline, chevronForwardSharp } from 'ionicons/icons';
import fruttaMeClient from '@/core/api/fruttame-service';
import { mapState } from 'vuex';
import {
    IResult,
    PlantReferenceDTO,
    SupplierDashboardTransportDTO,
} from '@/../client-generator/generated-clients/client';
import { SET_APP_LOADING } from '@/core/store/action-types';
import { DEFAULT_WEIGHT } from '@/core/utilities/utils';
import { PAGE_SALES_PLANNING_DETAIL } from '@/core/router/pages';

@Options({
    name: 'SalesPlanningPage',
    computed: {
        ...mapState(['currentPlant']),
    },
})
export default class SalesPlanningPage extends Vue {
    currentPlant!: PlantReferenceDTO | null;
    chevronForwardOutline = chevronForwardOutline;
    chevronForwardSharp = chevronForwardSharp;
    salesPlanningList: SupplierDashboardTransportDTO[] = [];
    defaultWeight = DEFAULT_WEIGHT;
    get countSalesPlanned(): number {
        return this.salesPlanningList.length;
    }
    get countSalesNotPlanned(): number {
        //TODO: refactor state with enum as int!
        return this.salesPlanningList.reduce((total, sale) => {
            if ((sale.state || (0 as number)) === 0) total++;

            return total;
        }, 0);
    }
    get groupOfSalesPlanning(): {
        [week: string]: SupplierDashboardTransportDTO[];
    } {
        if (this.salesPlanningList.length === 0) return {};
        const _salesPlanningList: SupplierDashboardTransportDTO[] = JSON.parse(
            JSON.stringify(this.salesPlanningList)
        );

        // group for year+week
        const _groupOfSalesPlanning: {
            [week: string]: SupplierDashboardTransportDTO[];
        } = {};
        _salesPlanningList.reduce((groupWeekDictionary, sale) => {
            if (!sale.loadDate) {
                !groupWeekDictionary['Unknow'] &&
                    (groupWeekDictionary['Unknow'] = []);
                groupWeekDictionary['Unknow'].push(sale);
                return groupWeekDictionary;
            }
            const d = new Date(sale.loadDate);
            const key = d.getFullYear() + '/' + this.getWeek(d);
            !groupWeekDictionary[key] && (groupWeekDictionary[key] = []);
            groupWeekDictionary[key].push(sale);
            return groupWeekDictionary;
        }, _groupOfSalesPlanning);
        return _groupOfSalesPlanning;
    }

    async mounted() {
        await this.fetchSalesPlanning();
    }
    formatWeek(weekString: string) {
        if (!weekString.includes('/')) return weekString;
        const weekNumber = weekString.split('/')[1]; // es: 2021/7
        return +weekNumber < 10 ? `0${weekNumber}` : weekNumber;
    }
    formatDate(dateString: string) {
        const _date = new Date(dateString);
        if (isNaN(_date.getTime())) return '-';
        return _date.toLocaleString(undefined, {
            weekday: 'short',
            month: '2-digit',
            day: '2-digit',
        });
    }
    formatHours(dateString: string) {
        const _date = new Date(dateString);
        if (isNaN(_date.getTime())) return '-';
        return _date.toLocaleString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
        });
    }
    async fetchSalesPlanning() {
        if (!this.currentPlant || !this.currentPlant.plantId) return;

        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            const _salesPlanningList: SupplierDashboardTransportDTO[] = (
                await fruttaMeClient.transports('it', this.currentPlant.plantId)
            ).data as SupplierDashboardTransportDTO[];
            // sort for date
            _salesPlanningList.sort((a, b) => {
                const dateA = a.loadDate
                    ? new Date(a.loadDate).getTime() || 0
                    : 0;
                const dateB = b.loadDate
                    ? new Date(b.loadDate).getTime() || 0
                    : 0;
                return dateA - dateB;
            });

            this.salesPlanningList = _salesPlanningList;
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult).errorMessage ?? this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    getWeek(date: Date) {
        // Create a copy of this date object
        const target = new Date(date);
        // ISO week date weeks start on monday, so correct the day number
        const dayNr = (date.getDay() + 6) % 7;
        // Set the target to the thursday of this week so the
        // target date is in the right year
        target.setDate(target.getDate() - dayNr + 3);
        // ISO 8601 states that week 1 is the week with january 4th in it
        const jan4 = new Date(target.getFullYear(), 0, 4);
        // Number of days between target date and january 4th
        const dayDiff = (target.getTime() - jan4.getTime()) / 86400000;
        if (new Date(target.getFullYear(), 0, 1).getDay() < 5) {
            // Calculate week number: Week 1 (january 4th) plus the
            // number of weeks between target date and january 4th
            return 1 + Math.ceil(dayDiff / 7);
        } else {
            // jan 4th is on the next week (so next week is week 1)
            return Math.ceil(dayDiff / 7);
        }
    }

    openTransport(transportId: number) {
        this.$router.push({
            name: PAGE_SALES_PLANNING_DETAIL,
            params: {
                transportId,
            },
        });
    }
}
